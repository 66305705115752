
.custom-scrollbar::-webkit-scrollbar-thumb {
  border: 3px solid lightblue;
  background-color: lightblue;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: lightblue; /* Ensure the same color on hover */
}

.custom-scrollbar {
  scrollbar-color: lightblue #f1f1f1; /* Firefox scrollbar color */
}

.custom-scrollbar:hover {
  scrollbar-color: lightblue #f1f1f1; /* Firefox scrollbar color on hover */
}

.driver-popover.driverjs-theme {
  background-color: #f1faff;
  color: #000;

}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #000;
}

.driver-popover.driverjs-theme button {
  flex: 1;
  text-align: center;
  background-color: #1570EF;
  color: #ffffff;
  text-shadow: none;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 6px;
}

.driver-popover.driverjs-theme button:hover {
  background-color: #175CD3 ;
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  text-shadow: none !important;
  background-color: #f1faff !important;
  color: #f1faff !important;
  cursor: default;
}
.driver-popover.driverjs-theme .driver-popover-next-btn {
  flex: 1;
  text-align: center;
  background-color: #1570EF;
  color: #ffffff;
  text-shadow: none;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 6px;
}
.driver-popover.driverjs-theme .driver-popover-prev-btn {
  flex: 1;
  text-align: center;
  background-color: #1570EF;
  color: #ffffff;
  text-shadow: none;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 6px;
}



#kt_charts_widget_5_chart text tspan:nth-child(2){
font-weight: 700;
}
.symbol-group.symbol-hover.projects .symbol {
  cursor:default;
}

.symbol-group.projects .symbol:hover {
  transition: all 0.3s ease;
  z-index: 0;
}

.fade.show.tooltip.bs-tooltip-end {
  position: absolute !important;
}
.zone {      
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  background-color: #F5FAFF;
  width:80%;
  height:50%;  
  border:2px dashed #1570EF;
  text-align:center;
  color: #1570EF;
  z-index: 20;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  box-shadow: 0 0 0 1px rgba(255,255,255,.05),inset 0 0 .25em 0 rgba(0,0,0,.25);
  .btnCompression {
    .active {
      background: #1570EF;
      color:white;
    }
  }
  i {
    text-align: center;
    font-size: 10em;  
    color:#fff;
    margin-top: 50px;
  }
  .selectFile {
    height: 50px;
    margin: 20px auto;
    position: relative;
    width: 200px;          
  }

  label, input {
    cursor: pointer;
    display: block;
    height: 50px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 5px;          
  }

  label {
    text-transform: uppercase;
  }

  input[type=file] {
    opacity: 0;
  }

}
.zone.in {
  color:white;
  border-color:white;
  background: radial-gradient(ellipse at center,#1570EF 0,#1570EF 100%);
  i {          
    color:#fff;
  }
  label {
    background: #fff;
    color:#1570EF;
  }
}
.zone.hover {
  color:gray;
  border-color:white;
  background:#fff;
  border:5px dashed gray;
  i {          
    color:#1570EF;
  }
  label {
    background: #fff;
    color:#1570EF;
  }
}
.zone.fade {
  transition: all 0.3s ease-out;
  opacity: 1;
}
//
// Card
//
span.ant-tree-node-content-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
#kt_account_profile_details .flag-dropdown {
  border-top: 1px solid #f9f9f9;
  border-bottom: 1px solid #f9f9f9;
  border-left: 1px solid #f9f9f9;
    background-color: #f9f9f9 !important;
    border-right: 1px solid #f9f9f9 !important;
    z-index: 0 !important;
}
.react-tel-input .flag-dropdown {
    border-left: 1px solid #e1e3ea;
    background-color: white !important;
    border-right: 1px solid #e1e3ea !important;
    border-radius: 3px 0 0 3px;
    border-top: 1px solid #e1e3ea;
    border-bottom: 1px solid #e1e3ea;
}
body#kt_app_body {
  height: 100% !important;
}
.chakra-modal__body .rdg.r104f42s700-beta11.rdg-light.undefined  {
  grid-template-columns: 35px 144px 144px 144px 144px !important;
}
.chakra-modal__body .rdg-static {
  grid-template-columns: 35px 161px 162px 161px 162px 161px 162px 161px 162px 161px 162px 161px !important;
}

#react-select-5-listbox{
  z-index: 9999 !important;
}
.my-custom-scrollbar {
  position: relative;
  width: 800px;
  height: 400px;
  overflow: auto;
}

.sc-dicizt{
  font-size: 15px !important;
  font-weight: bold !important;
}
.flag-dropdown{
  z-index: 500000 !important;
}
.rti--input{
  background-color: transparent !important;
  padding: 5px !important;
}
.rti--container{
  background-color: var(--bs-gray-100) !important;
  border-color: var(--bs-gray-100) !important;
  color: var(--bs-gray-700) !important;
  transition: color 0.2s ease !important;
}
// Base
.card {
  @if ($card-border-enabled) {
    border: $card-border-width $card-border-style var(--#{$prefix}card-border-color);
  } @else {
    border: 0;
  }

  // Header
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: $card-header-height;
    padding: 0 $card-px;
    color: var(--#{$prefix}card-cap-color);
    background-color: var(--#{$prefix}card-cap-bg);
    border-bottom: $card-border-width $card-border-style var(--#{$prefix}card-border-color);

    // Title
    .card-title {
      display: flex;
      align-items: center;
      margin: $card-header-py;
      margin-left: 0;

      &.flex-column {
        align-items: flex-start;
        justify-content: center;
      }

      .card-icon {
        margin-right: 0.75rem;
        line-height: 0;

        i {
          font-size: 1.25rem;
          color: var(--#{$prefix}gray-600);
          line-height: 0;

          &:after,
          &:before {
            line-height: 0;
          }
        }

        .svg-icon {
          color: var(--#{$prefix}gray-600);
          @include svg-icon-size(24px);
        }
      }

      &,
      .card-label {
        font-weight: 500;
        font-size: 1.275rem;
        color: var(--#{$prefix}text-dark);
      }

      .card-label {
        margin: 0 0.75rem 0 0;
        flex-wrap: wrap;
      }

      // Description
      small {
        color: var(--#{$prefix}text-muted);
        font-size: 1rem;
      }

      // Headings
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0;
      }
    }

    // Toolbar
    .card-toolbar {
      display: flex;
      align-items: center;
      margin: $card-header-py 0;
      flex-wrap: wrap;
    }
  }

  // Body
  .card-body {
    padding: $card-py $card-px;
    color: var(--#{$prefix}card-color);
  }

  // Footer
  .card-footer {
    padding: $card-py $card-px;
    color: var(--#{$prefix}card-cap-color);
    background-color: var(--#{$prefix}card-cap-bg);
    border-top: $card-border-width $card-border-style var(--#{$prefix}card-border-color);
  }

  // Scroll
  .card-scroll {
    position: relative;
    overflow: auto;
  }

  // Reset padding x
  &.card-px-0 {
    .card-header,
    .card-body,
    .card-footer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.card-py-0 {
    .card-header,
    .card-body,
    .card-footer {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.card-p-0 {
    .card-header,
    .card-body,
    .card-footer {
      padding: 0;
    }
  }

  // Dashed style
  &.card-dashed {
    box-shadow: none;
    border: $card-border-width dashed $card-border-dashed-color;

    > .card-header {
      border-bottom: 1px dashed $card-border-dashed-color;
    }

    > .card-footer {
      border-top: 1px dashed $card-border-dashed-color;
    }
  }

  // Bordered style
  &.card-bordered {
    box-shadow: none;
    border: $card-border-width $card-border-style $card-border-color;
  }

  // Flush header and footer borders
  &.card-flush {
    > .card-header {
      border-bottom: 0 !important;
    }

    > .card-footer {
      border-top: 0 !important;
    }
  }

  // Enable shadow
  &.card-shadow {
    box-shadow: var(--#{$prefix}card-box-shadow);
    border: 0;
  }

  // Reset styles
  &.card-reset {
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;

    > .card-header {
      border-bottom: 0 !important;
    }

    > .card-footer {
      border-top: 0 !important;
    }
  }

  // Reset borders
  &.card-borderless {
    border: 0 !important;

    > .card-header {
      border-bottom: 0 !important;
    }

    > .card-footer {
      border-top: 0 !important;
    }
  }

  // Reset borders
  &.card-border-0 {
    border: 0 !important;
  }
}

// Responsive stretch heights
.card {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      // Stretch
      &.card#{$infix}-stretch {
        height: calc(100% - var(--#{$prefix}gutter-y));
      }

      // Stretch 75
      &.card#{$infix}-stretch-75 {
        height: calc(75% - var(--#{$prefix}gutter-y));
      }

      // Stretch 50
      &.card#{$infix}-stretch-50 {
        height: calc(50% - var(--#{$prefix}gutter-y));
      }

      // Stretch 33
      &.card#{$infix}-stretch-33 {
        height: calc(33.333% - var(--#{$prefix}gutter-y));
      }

      // Stretch 25
      &.card#{$infix}-stretch-25 {
        height: calc(25% - var(--#{$prefix}gutter-y));
      }

      // Header stretch
      .card-header#{$infix}-stretch {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        align-items: stretch;

        .card-toolbar {
          margin: 0;
          align-items: stretch;
        }
      }
    }
  }
}

// Utilities
.card-p {
  padding: $card-py $card-px !important;
}

.card-px {
  padding-left: $card-px !important;
  padding-right: $card-px !important;
}

.card-shadow {
  box-shadow: var(--#{$prefix}card-box-shadow);
}

.card-py {
  padding-top: $card-py !important;
  padding-bottom: $card-py !important;
}

.card-rounded {
  border-radius: $card-border-radius;
}

.card-rounded-start {
  border-top-left-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
}

.card-rounded-end {
  border-top-right-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

.card-rounded-top {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.card-rounded-bottom {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

// Mobile mode
@include media-breakpoint-down(md) {
  .card {
    > .card-header:not(.flex-nowrap) {
      padding-top: $card-header-py;
      padding-bottom: $card-header-py;
    }
  }
}

@if $enable-dark-mode {
  @include color-mode(dark) {
    .card {
      --#{$prefix}card-box-shadow: #{$card-box-shadow-dark};
    }
  }
}
