//
// Content
//
@include media-breakpoint-up(lg) {
  .app-content {
    [data-kt-app-toolbar-enabled='true']:not([data-kt-app-toolbar-fixed='true']) & {
      padding-top: 0;
    }
  }
}
// Desktop mode
@include media-breakpoint-up(lg) {
  .app-content {
    padding-top: 0;
    padding-bottom: $app-content-padding-y;
    padding-left: $app-content-padding-x;
    padding-right: $app-content-padding-x;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-content {
    max-width: none;
    padding-top: $app-content-padding-y-mobile;
    padding-bottom: $app-content-padding-y-mobile;
    padding-left: $app-content-padding-x-mobile;
    padding-right: $app-content-padding-x-mobile;
  }
}
